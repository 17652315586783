if ( $('.js-slides').length ) {
    $('.js-slides').on('scroll', function(event){
        var scroll = $(this).scrollTop(),
        viewportHeight = $(window).height();
        $('.js-slide').each(function(i){
            var height = $(this).outerHeight(true);
            var top = Math.floor( $(this).offset().top + i*height ); // offset().top is relative to scroll
            var bottom = Math.floor(top+height);
            if ( (scroll >= (top/2)) && !( bottom <= scroll ) ) {
                $(this).addClass('is-active').siblings('.js-slide.is-active').removeClass('is-active');
                var index = (i+1);
                if ( index < 10 ) {
                    index = "0"+index;
                }
                $('.js-slideIndex').text(index);
                if ( $(this).attr('id') == 'text' ) {
                    $('body').addClass('is-textInView');
                    $('.js-scrollNav').attr('href', '#image01');
                } else if ( $('body').hasClass('is-textInView') ) {
                    $('body').removeClass('is-textInView');
                    $('.js-scrollNav').attr('href', '#text');
                }
                var nextImage = $(this).next('.js-slide').find('img');
                if ( nextImage )
                    nextImage.attr('loading','eager');
                // if( history.pushState ) {
                //     var hash = "#"+$(this).attr('id');
                //     history.pushState(null, null, hash);
                // }
                return false;
            }
        });
        $('.js-slide#text').each(function(){
            if ( $('.js-slide#text').offset().top < 0 ) {
                $('body').addClass('is-textOverflows');
            } else {
                $('body').removeClass('is-textOverflows');
            }
        });
        $('.js-footerColumn').each(function(i){
            var top = Math.round($(this).offset().top),
                height = $(this).outerHeight();
            console.log(top);
            if ( top > (height*-1) && top < viewportHeight ) {
                $(this).addClass('is-inView');
            } else {
                if ( $(this).hasClass('is-inView') ) {
                    $(this).removeClass('is-inView');
                }
            }
        });
        // if ( $('.js-footer').length ) {
        //     var footerTop = $('.js-footer').offset().top,
        //         footerBottom = footerTop - $('.js-slides').outerHeight();
        //     if ( footerBottom < ($('.Header').outerHeight(true) * -1) ) {
        //         $('body').addClass('is-fullFooterInView');
        //     } else {
        //         $('body').removeClass('is-fullFooterInView');
        //     }
        // }
    });
    $('.js-slides').trigger('scroll');

    $(document).ready(function(){
        var hash = window.location.hash;
        if( hash ) {
            var top = $('.js-slide'+hash).offset().top;
            $('.js-slides').scrollTop(top);
        }
    });
}

if ( $('.js-home').length ) {
    $('.js-home').on('scroll', function(event){
        var scroll = $(this).scrollTop(),
            viewportHeight = $(window).height(),
            headerHeight = Math.round($('.Header').outerHeight(true));
        $('.js-case').each(function(i){
            var top = Math.round($(this).offset().top),
                height = $(this).outerHeight();
            if ( top > (height*-1) && top < viewportHeight ) {
                $(this).addClass('is-inView');
            } else {
                if ( $(this).hasClass('is-inView') ) {
                    $(this).removeClass('is-inView');
                }
                if ( scroll == 0 ) {
                    if ( top < (viewportHeight * 2 - headerHeight) ) {
                        $(this).find('img').attr('loading','eager');
                    }
                } else {
                    if ( top == viewportHeight ) {
                        $(this).find('img').attr('loading','eager');
                    }
                }
            }
        });
        $('.js-packery').each(function(i){
            var top = Math.round($(this).offset().top),
                height = $(this).outerHeight();
            if ( top < 0 ) {
                $('body').addClass('is-passedHeader');
            } else {
                if ( $('body').hasClass('is-passedHeader') ) {
                    $('body').removeClass('is-passedHeader');
                }
            }
        });
        $('.js-footerColumn').each(function(i){
            var top = Math.round($(this).offset().top),
                height = $(this).outerHeight();
            if ( top > (height*-1) && top < viewportHeight ) {
                $(this).addClass('is-inView');
            } else {
                if ( $(this).hasClass('is-inView') ) {
                    $(this).removeClass('is-inView');
                }
            }
        });
    });
    $('.js-home').trigger('scroll');
}

if ( $('.js-page').length ) {
    $('.js-page').on('scroll', function(event){
        var scroll = $(this).scrollTop(),
        viewportHeight = $(window).height();
        $('.js-item').each(function(i){
            if ( $(this).offset().top > 0 ) {
                $(this).addClass('is-inView');
            } else {
                $(this).removeClass('is-inView');
            }
        });
        $('.js-section').each(function(i){
            var top = $(this).offset().top,
                bottom = top + $(this).height(),
                headerHeight = Math.round($('.Header').outerHeight(true));
            if ( bottom > 0 && top <= headerHeight ) {
                var id = $(this).attr('id');
                $('.Header-projectLink[data-id="'+id+'"]').addClass('Header-activeLink').siblings('.Header-projectLink').removeClass('Header-activeLink');
                $(this).addClass('is-inView');
            } else {
                $(this).removeClass('is-inView');
            }
        });
        $('.js-footerColumn').each(function(i){
            var top = Math.round($(this).offset().top),
                height = $(this).outerHeight();
            if ( top > (height*-1) && top < viewportHeight ) {
                $(this).addClass('is-inView');
            } else {
                if ( $(this).hasClass('is-inView') ) {
                    $(this).removeClass('is-inView');
                }
            }
        });
    });
    $('.js-page').trigger('scroll');
}
